export const BOOTSTRAP_CUSTOMERS = 'BOOTSTRAP_CUSTOMERS'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const DELETE_MULTIPLE_CUSTOMERS = 'DELETE_MULTIPLE_CUSTOMERS'
export const SET_SELECTED_CUSTOMERS = 'SET_SELECTED_CUSTOMERS'
export const SET_TOTAL_CUSTOMERS = 'SET_TOTAL_CUSTOMERS'
export const RESET_SELECTED_CUSTOMER = 'RESET_SELECTED_CUSTOMER'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_SELECTED_VIEW_CUSTOMER = 'SET_SELECTED_VIEW_CUSTOMER'
